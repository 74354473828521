import React, { Component } from "react";
//import PropTypes from "prop-types";
import { connect } from "react-redux";
//import TextAreaFieldGroup from "../common/TextAreaFieldGroup";
import {
  ReactiveBase,
  ResultCard,
  CategorySearch,
  SingleRange,
  DataSearch,
  ReactiveList,
  ResultList,
  SelectedFilters,
  TextField
} from "@appbaseio/reactivesearch";
import { getSearchResults } from "../../actions/searchResultAction";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
//import Profiles from '../profiles/Profiles';

//import { Link } from 'react-router-dom';
//import elasticsearch from "elasticsearch";
//import Spinner from '../common/Spinner';

//import PostForm from '../posts/PostForm';
const { ResultCardsWrapper } = ReactiveList;

class SearchResult extends Component {
  componentDidMount() {
    this.props.getSearchResults();
  }

  render() {
    return (
      <ReactiveBase
      //app="kundendatenbank_aromaladen"
        app="placeanads"
        //url="http://emmtop.de:9200"
        //url="http://83.169.33.43:9200"
        url="http://localhost:9200"
      >
        <DataSearch
          componentId="searchbox"
          dataField="Absender E-Mail-Adresse"
          categoryField="message.keyword"
          placeholder="Search"
        />

        <ReactiveList
          title="Results"
          from={0}
          size={5}
          pagination={true}
          componentId="SearchResult"
          react={{
            and: ["searchbox", "SearchResult"]
          }}
          renderItem={res => (
            <ResultCard title="Results">
              <div>{res.message}</div>
            </ResultCard>
          )}
        />
      </ReactiveBase>
    );
  }
  /*  
  
onData(data){
  return{
    title: (
      <div
        className="book-title"
				dangerouslySetInnerHTML={{ __html: data.placeanad }}
				
      />
    ),
  };
}

*/
}

const mapStateToProps = state => ({
  post: state.post
});

export default connect(
  mapStateToProps,
  { getSearchResults }
)(SearchResult);
