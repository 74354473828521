import axios from "axios";

import {
  ADD_POST,
  GET_ERRORS,
  CLEAR_ERRORS,
 
  POST_LOADING,
  
  GET_SEARCH_RESULT
} from "./types";

// Get Search Results
export const getSearchResult = () => dispatch => {
  dispatch(setPostLoading());
  axios
    .get("/api/search-results")
    .then(res =>
      dispatch({
        type: GET_SEARCH_RESULT,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_SEARCH_RESULT,
        payload: null
      })
    );
};

// Get Post
export const getSearchResults = id => dispatch => {
  dispatch(setPostLoading());
  axios
    .get(`/api/search-results/${id}`)
    .then(res =>
      dispatch({
        type: GET_SEARCH_RESULT,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_SEARCH_RESULT,
        payload: null
      })
    );
};

// Set loading state
export const setPostLoading = () => {
  return {
    type: POST_LOADING
  };
};

export const addPost = postData => dispatch => {
  dispatch(clearErrors());
  axios
    .post("/api/posts", postData)
    .then(res =>
      dispatch({
        type: ADD_POST,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};
